'use strict'
const _ = require('lodash')
const {PublishMethods, factory} = require('@wix/web-bi-logger/dist/src/logger')
const {create} = require('@wix/fedops-logger')

const MESSAGE_TYPE = 'reportBI'
const MESSAGE_INTENT = 'WIX_CODE_SSR'
const PRESET = 'BOLT'
const BI_ENDPOINT = 'bolt-performance'

const serverBiLoggerFactory = (biStore, muteFunc, postMessageFunc, isFedonomyActive = false) => {
    const factoryInstance = factory({
        publishMethod: PublishMethods.PostMessage,
        useBatch: false
    })
        .setMuted(muteFunc())
        .withUoUContext({
            msid: () => biStore.metaSiteId,
            ...isFedonomyActive && {
                visitorId: biStore.visitorId,
                siteMemberId: () => biStore.siteMemberId
            }
        })
        .updateDefaults({
            ts: () => biStore.getLoadingTime(),
            tsn: () => biStore.getTotalLoadingTime(),
            pn: 1,
            pageId: () => biStore.pageId,
            pageUrl: () => biStore.pageUrl,
            _av: biStore.artifactVersion,
            isServerSide: true,
            is_lightbox: biStore.isPopup,
            ...isFedonomyActive && {
                is_rollout: biStore.is_rollout,
                is_cached: biStore.isCached,
                dc: biStore.dc,
                ish: biStore.is_headless // can be null for now, doesn't check anything  
            }
        })
        .withTransformer({
            [PublishMethods.PostMessage]: params => ({
                type: MESSAGE_TYPE,
                intent: MESSAGE_INTENT,
                params
            })
        })

    if (_.isFunction(postMessageFunc)) {
        factoryInstance.withPublishFunction({
            [PublishMethods.PostMessage]: postMessageFunc
        })
    }

    return factoryInstance
}

const clientBiLoggerFactory = (biStore, muteFunc, useBatch, isFedonomyActive = false) => 
    factory({publishMethod: PublishMethods.Auto, useBatch})
        .setMuted(muteFunc())
        .withUoUContext({
            msid: () => biStore.metaSiteId,
            visitorId: biStore.visitorId,
            siteMemberId: () => biStore.siteMemberId
        })
        .updateDefaults({
            ts: () => biStore.getLoadingTime(),
            tsn: () => biStore.getTotalLoadingTime(),
            pn: () => biStore.pageNumber,
            pageId: () => biStore.pageId,
            pageUrl: () => biStore.pageUrl,
            rid: biStore.requestId,
            _av: biStore.artifactVersion,
            isServerSide: false,
            is_lightbox: biStore.isPopup,
            ...isFedonomyActive && {
                is_rollout: biStore.is_rollout,
                is_cached: biStore.isCached,
                dc: biStore.dc,
                ish: biStore.is_headless // can be null for now, doesn't check anything  
            }
        })

const getServerFedOpsLogger = (biStore, muteFunc, postMessageFunc, experiments) => {
    const isFedonomyActive = experiments && experiments.has('bv_newFedopsEndpoint')
    const endpoint = isFedonomyActive ? 'bolt-viewer' : 'platform-logger'
    return create(endpoint, {
        isServerSide: true,
        biLoggerFactory: serverBiLoggerFactory(biStore, muteFunc, postMessageFunc, isFedonomyActive),
        phasesConfig: 'SEND_ON_APP_LOADED',
        customParams: {
            isMobileFriendly: biStore.isMobileFriendly,
            viewerName: biStore.viewerName,
            viewMode: biStore.viewMode
        },
        isPersistent: true,
        ...isFedonomyActive && {
            metasiteId: biStore.metaSiteId,
            presetType: PRESET,
            endpoint: BI_ENDPOINT
        }
    })
}


const getClientFedOpsLogger = (biStore, muteFunc, useBatch, experiments) => {
    const isFedonomyActive = experiments && experiments.has('bv_newFedopsEndpoint')
    const endpoint = isFedonomyActive ? 'bolt-viewer' : 'platform-logger'
    const fedopsCreationParams = {
        isServerSide: false,
        biLoggerFactory: clientBiLoggerFactory(biStore, muteFunc, useBatch, isFedonomyActive),
        phasesConfig: 'SEND_ON_APP_LOADED',
        customParams: {
            isMobileFriendly: biStore.isMobileFriendly,
            viewerName: biStore.viewerName,
            viewMode: biStore.viewMode
        },
        isPersistent: true,
        ...isFedonomyActive && {
            metasiteId: biStore.metaSiteId,
            presetType: PRESET,
            endpoint: BI_ENDPOINT

        }
    }
    return create(endpoint, fedopsCreationParams)
}

const getFedOpsPlatformLoggers = (biStore, muteFunc, postMessageFunc, useBatch, endpoint, experiments) => ({
    server: getServerFedOpsLogger(biStore, muteFunc, postMessageFunc, experiments),
    client: getClientFedOpsLogger(biStore, muteFunc, useBatch, experiments)
})

const getBiLoggers = (biStore, muteFunc, postMessageFunc, useBatch, endpoint) => ({
    server: serverBiLoggerFactory(biStore, muteFunc, postMessageFunc).logger({endpoint}),
    client: clientBiLoggerFactory(biStore, muteFunc, useBatch).logger({endpoint})
})

const biLoggerFactoryForApp = (isServerSide, biStore, muteFunc, postMessageFunc, useBatch) => appDefaults => () => {
    const mapToUnderscoreIfNotNullUndefined = (res, value, key) => !_.isNil(value) ? _.set(res, `_${key}`, value) : res
    const mappedAppDefaults = _.reduce(appDefaults, mapToUnderscoreIfNotNullUndefined, {})

    const defaults = Object.assign({}, {
        rid: biStore.requestId,
        _av: biStore.artifactVersion,
        _siteOwnerId: biStore.ownerId,
        _viewMode: biStore.viewMode
    }, mappedAppDefaults)

    const publishMethod = isServerSide ? PublishMethods.PostMessage : PublishMethods.Auto

    const factoryInstance = factory({useBatch, publishMethod})
        .setMuted(muteFunc())
        .withUoUContext({
            msid: () => biStore.metaSiteId,
            visitorId: biStore.visitorId,
            siteMemberId: () => biStore.siteMemberId
        })
        .updateDefaults(defaults)

    if (isServerSide) {
        factoryInstance.withTransformer({
            [PublishMethods.PostMessage]: params => ({
                type: MESSAGE_TYPE,
                intent: MESSAGE_INTENT,
                params
            })
        })
    }

    if (_.isFunction(postMessageFunc)) {
        factoryInstance.withPublishFunction({
            [PublishMethods.PostMessage]: postMessageFunc
        })
    }
    return factoryInstance
}

const getBiLoggerFactoriesForApp = (biStore, muteFunc, postMessageFunc, useBatch) => ({
    server: biLoggerFactoryForApp(true, biStore, muteFunc, postMessageFunc, useBatch),
    client: biLoggerFactoryForApp(false, biStore, muteFunc, postMessageFunc, useBatch)
})

const getFedOpsLoggerFactory = () => create

module.exports = {
    getFedOpsLoggerFactory,
    getFedOpsPlatformLoggers,
    getBiLoggers,
    getBiLoggerFactoriesForApp
}
